import React, { useState } from "react";
import Home from './Home';
import './App.css';
import { Login } from './Login';
import { Register } from './Register';


function App() {
  const [currentForm, setCutrrentForm] = useState('login');

  const toggleForm = (formName) => (
    setCutrrentForm(formName)
  );

  return (
    
    <div className="App">
      {
        currentForm === "login" ? 
        <Login onFormSwitch={toggleForm}
         /> : 
        <Register onFormSwitch={toggleForm} />
  
      }

    </div>
    

  );

}

export default App;
