import React, { useState } from "react";
// import ReactDOM from "react-dom";
import { useForm } from "react-hook-form";

import './Register.css';







export const Register = (props) => {
    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');
    const [repass, setRePass] = useState('');
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [address, setAddress] = useState('');
    // const options = useState('Male', 'Female');
    // const Menus = ['Please select', 'Male', 'Female'];
    const { register, handleSubmit } = useForm();
    const onSubmit = (data) => console.log(data);

    const [agree, setAgree] = useState(false);

    const checkboxHandler = () => {
      // if agree === true, it will be set to false
      // if agree === false, it will be set to true
      setAgree(!agree);
      // Don't miss the exclamation mark
    }
  
    // When the button is clicked









    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     console.log(email);
    // }

    return (
        <div className="form-container">
            <h2>Register</h2>
        <form className="register-form" onSubmit={handleSubmit}>
            <label htmlFor="name">Name</label>
            <input value={name} onChange={(e) => setName(e.target.value)} name="name" id="name" placeholder="Name" required minlength="3" maxlenth="15"/>
            <label htmlFor="surname">Surname</label>
            <input value={surname} onChange={(e) => setSurname(e.target.value)} name="surname" id="surname" placeholder="surname" required minlength="3" maxlenth="15" />
            <label htmlFor="address">Address</label>
            <input value={address} onChange={(e) => setAddress(e.target.value)} name="address" id="address" placeholder="Address" />


    {/* <form>
      <select>
        {options.map((value) => (
          <option value={value} key={value}>
            {value}
          </option>
        ))}
      </select>
    </form> */}
  

  <label htmlFor="gender">Gender</label>

  <form className="gender" onSubmit={handleSubmit(onSubmit)}>

      <select className="dropbtn" {...register("gender")}>
        <option value="other">other</option>
        <option value="female">female</option>
        <option value="male">male</option>

      </select>

    </form>

    <label htmlFor="agree"> <b>I agree to terms and conditions</b></label>
    <input type="checkbox" id="agree" onChange={checkboxHandler}required />
         
    <label htmlFor="agree"> Subscribe to news letter</label>
          <input type="checkbox" id="agree" onChange={checkboxHandler} />
      





            {/* <div className="gender">
                <label class="gender">Gender</label>
 



            </div> */}
{/* 
            <label htmlFor="gender">Gender</label>
            <div className="gender, dropdown">
                <ul className="dropbtn">{
                Menus.map((menu) =>(
                 
                    <li className="list, dropdown-content" key={menu}>{menu}

                    </li>
                ))}
                </ul>
            </div> */}
            {/* <input value={gender} onChange={(e) => setGender(e.target.value)} name="gender" id="gender" placeholder="gender" /> */}

            <label for="email">email</label>
            <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder="email@mail.com" id="email" name="email" required />
            <label for="password">password</label>
            <input value={pass} onChange={(e) => setPass(e.target.value)} type="password" placeholder="*********" id="password" name="password" required minlength="8" maxlength="15"/>

            <label for="repassword">Repead password</label>
            <input value={repass} onChange={(e) => setRePass(e.target.value)} type="password" placeholder="*********" id="repassword" name="repassword" required minlength="8" maxlength="15"/>


            <button className="btn" type="submit">Complete registration</button>
        </form>
        <button className="link-btn" onClick={() => props.onFormSwitch('login')}>Alredy have an account? Login here</button>
        </div>






    )
}